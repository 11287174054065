








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import TaskType from '@/shared/modules/task-type/task-type.model'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class TaskTypesCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/task-types`)
    .setModel(TaskType)
    .setTranslatable(true)
    .setValidate(true, { onlyRegionLangRequired: true })
    .setFields([
      new Field()
        .setTranslatable(true)
        .setKey('name')
        .setTitle(__('company.views.task-types.form.name'))
        .isRequired(),
      new Field()
        .setType(FieldTypes.textArea)
        .setTranslatable(true)
        .setKey('description')
        .setTitle(__('company.views.task-types.form.description')),
      new Field()
        .setType(FieldTypes.number)
        .setKey('amount')
        .setTitle(__('company.views.task-types.form.amount')),
      new Field()
        .setType(FieldTypes.number)
        .setKey('tariff')
        .setTitle(__('company.views.task-types.form.tariff')),
    ])
  .setSubmit({
    text: __('company.views.task-types.create.form.submit'),
  })
  .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.taskTypesIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.task-types.create.form.on-success'),
    })

    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
